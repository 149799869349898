html {
  background-color: rgb(229, 229, 229);
}

html,
body {
  margin: 0;
  font-family: 'Inter Variable', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: 'Inter Variable', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}

#root {
  height: 100%;
  max-height: 100%;
}
