.ql-editor .ql-snow {
  width: 100%;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0 !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  /* content: 'Inter Variable' !important; */
  font-family: 'Inter Variable' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='Inter']::before,
.ql-picker.ql-font .ql-picker-item[data-value='Inter']::before {
  /* font-family: 'Inter Variable'; */
  content: 'InterVariable' !important;
}

.ql-picker.ql-font .ql-picker-label[data-value='NewtonPhonetic']::before,
.ql-picker.ql-font .ql-picker-item[data-value='NewtonPhonetic']::before {
  /* font-family: 'NewtonPhonetic'; */
  content: 'NewtonPhonetic' !important;
}

/* Set content font-families */
.ql-font-Inter {
  font-family: 'Inter Variable';
}
.ql-font-NewtonPhonetic {
  font-family: 'NewtonPhonetic';
}

.react-multi-carousel-item::before {
  content: none !important;
}
.react-multi-carousel-item {
  padding: 0 !important;
}
