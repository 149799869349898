.ql-toolbar.ql-snow {
  padding: 4px;
}

.ql-snow .ql-color-picker .ql-picker-label {
  padding-top: 4px;
}

.ql-snow .ql-color-picker .ql-picker-label svg {
  width: 14px;
  height: 14px;
}

.ql-snow .ql-picker-label {
  padding: 0px;
}

.ql-snow.ql-toolbar button {
  width: 14px;
  height: 14px;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 8px;
}

div.ql-editor {
  padding: 4px;
}

.quill {
  width: 100%;
}

div.ql-bubble {
  font-family: 'Inter Variable', sans-serif;
  font-size: 15px;
  font-weight: 450;
  letter-spacing: -0.02em;
  line-height: 16px;
}

.ql-tooltip {
  z-index: 2000;
}

.ql-bubble .ql-toolbar span.ql-formats {
  margin: 0px;
}

.ql-bubble div.ql-tooltip {
  background-color: #665646;
  border-radius: 4px;
  color: #fff;
}
